import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './AddOxygenDialog.scss';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { CheckCircleOutline } from '@mui/icons-material';

export interface AddOxygenDialogResponse {
  timestamp: Dayjs;
  oxygenFlowValue: number;
}

interface AddOxygenDialogProps {
  isDialogOpen: boolean;
  onOpenChanged: (open: boolean) => void;
  addOxygenCallback: (oxygen: AddOxygenDialogResponse) => void;
}

export function AddOxygenDialog(props: AddOxygenDialogProps) {
  useCSS(style);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const [oxygenValue, setOxygenValue] = useState<number | undefined>(undefined);
  const onOxygenValueChanged = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const txtValue = event.target.value;
    setOxygenValue(txtValue !== '' ? Number(txtValue) : undefined);
  };

  const [oxygenTimestamp, setOxygenTimestamp] = useState<Dayjs | null>(null);
  const onChangeOxygenTime = (newTime: Dayjs | null) => {
    setOxygenTimestamp(newTime);
  };

  function handleClose() {
    props.onOpenChanged(false);
    setTimeout(() => {
      setOxygenValue(undefined);
      setOxygenTimestamp(null);
      setIsConfirmed(false);
    }, 200);
  }

  function handleAdd() {
    if (!isAddable()) {
      return;
    }

    props.addOxygenCallback({ timestamp: oxygenTimestamp!, oxygenFlowValue: oxygenValue! });

    setIsConfirmed(true);
    setTimeout(handleClose, 1000);
  }

  function isAddable() {
    return oxygenValue !== undefined && oxygenTimestamp !== null && oxygenTimestamp.isValid();
  }

  return (
    <Dialog open={props.isDialogOpen} PaperProps={{ className: 'add-oxygen-dialog' }}>
      <DialogTitle>Sauerstoff hinzufügen</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-oxygen-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                fullWidth
                name='oxygenTimestamp'
                label='Zeitpunkt'
                format='HH:mm'
                value={oxygenTimestamp}
                onChange={onChangeOxygenTime}
              />
            </LocalizationProvider>
            <DebouncedTextField
              fullWidth
              type='number'
              label='Sauerstoffgabe [l/m]'
              variant='outlined'
              value={oxygenValue?.toString() ?? ''}
              onChange={onOxygenValueChanged}
              inputProps={{ step: 0.1, min: 0.1 }}
            />
          </div>
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          <Button className='grey-button' variant='contained' onClick={handleClose}>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} autoFocus disabled={!isAddable()} variant='contained'>
            Hinzufügen
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
