import { useCSS } from '../../../provider/CSSProvider';
import style from './AddPatientRecordDialog.scss';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { Node } from '../../../backendModels/report.model';
import { ReactComponent as DeleteIcon } from '../../../assets/images/icons/delete.svg';
import { CheckCircleOutline } from '@mui/icons-material';

interface AddPatientRecordDialogProps {
  open: boolean;
  onOpenChanged: (open: boolean) => void;
  createOrUpdatePatientRecord: (text: string, nodeToEdit?: Node) => void;
  deletePatientRecord: (node: Node) => void;
  nodeToEdit?: Node;
}

export default function AddPatientRecordDialog({
  open,
  onOpenChanged,
  createOrUpdatePatientRecord,
  deletePatientRecord,
  nodeToEdit,
}: AddPatientRecordDialogProps) {
  useCSS(style);

  const [text, setText] = useState<string>(
    nodeToEdit && nodeToEdit.records.length === 1 && nodeToEdit.records[0].type === 'note'
      ? (nodeToEdit.records[0].text ?? '')
      : '',
  );
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    if (nodeToEdit === undefined) {
      setText('');
    } else {
      setText(
        nodeToEdit.records.length === 1 && nodeToEdit.records[0].type === 'note'
          ? (nodeToEdit.records[0].text ?? '')
          : '',
      );
    }
  }, [nodeToEdit]);

  const close = () => {
    onOpenChanged(false);
    setTimeout(() => {
      setText('');
      setIsConfirmed(false);
    }, 200);
  };

  function saveAndClose() {
    if (text != '') {
      createOrUpdatePatientRecord(text, nodeToEdit);
    }

    setIsConfirmed(true);
    setTimeout(close, 1000);
  }

  function deleteAndClose() {
    if (nodeToEdit !== undefined) {
      deletePatientRecord(nodeToEdit);
    }

    setIsConfirmed(true);
    setTimeout(close, 1000);
  }

  function handleTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setText(e.target.value);
  }

  return (
    <Dialog open={open} onClose={close} className='AddPatientRecordDialog'>
      <DialogTitle className='title'>Neuer Eintrag</DialogTitle>
      <DialogContent>
        {isConfirmed ? (
          <div className='add-patient-dialog-confirmed'>
            <CheckCircleOutline className='check-circle' />
          </div>
        ) : (
          <TextField
            fullWidth
            multiline
            rows={10}
            label={'Hier Notizen eingeben'}
            value={text}
            onChange={handleTextChange}
          />
        )}
      </DialogContent>
      {!isConfirmed && (
        <DialogActions>
          {nodeToEdit !== undefined && (
            <Button variant='text' className='delete-button' onClick={deleteAndClose} startIcon={<DeleteIcon />}>
              Eintrag löschen
            </Button>
          )}
          <Button variant='contained' className='grey-button' onClick={close}>
            Abbrechen
          </Button>
          <Button variant='contained' onClick={saveAndClose} disabled={text === ''}>
            Speichern
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
