import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Ventilation.scss';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import {
  getVentilationTuple,
  getVentilationTuplesFor,
  VENTILATION_RECORD_ELEMENT_KEY,
  VentilationKeys,
  VentilationRecord,
} from '../../../../models/genericElements/treatments/ventilation';
import { NodeType } from '../../../../backendModels/report.model';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { Draft } from 'immer';
import { InputState } from '../../../../backendModels/general.model';
import { isGenericRecordDeletable } from '../../../../models/generic';

interface VentilationProps {
  nodeType: NodeType;
}

const ventilationItems = getVentilationTuplesFor([
  VentilationKeys.beatmungmanual,
  VentilationKeys.beatmungkontrolliert,
  VentilationKeys.beatmungassistiert,
  VentilationKeys.beatmungniv,
]);

const emergencyAnesthesiaItem = getVentilationTuple(VentilationKeys.beatmungnarkose);

export default function Ventilation({ nodeType }: VentilationProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('generic', nodeType, VENTILATION_RECORD_ELEMENT_KEY) as VentilationRecord;

  const ventilationValue = record.values?.beatmung?.option ?? null;

  function handleVentilationRadio(event: React.ChangeEvent<HTMLInputElement>) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        if (event.target.value != null) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            beatmung: {
              fieldType: 'singleSelect',
              option: event.target.value as VentilationKeys,
            },
          };
        } else {
          delete draft.values?.beatmung;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  }

  const changeFIO2 = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            beatmungFio2: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.beatmungFio2;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeAF = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            beatmungAf: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.beatmungAf;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  };

  const changeAMV = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            beatmungAmv: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.beatmungAmv;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  };

  const changePEEP = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            beatmungPeep: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.beatmungPeep;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  };

  const changePMax = (number: string) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (number !== '') {
          draft.values = {
            ...draft.values,
            beatmungPmax: {
              fieldType: 'numeric',
              number: Number(number),
            },
          };
        } else {
          delete draft.values?.beatmungPmax;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  };

  function setEmergancyAnesthesia(value: VentilationKeys[]) {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<VentilationRecord>, deleteRecord) => {
        if (value.length > 0) {
          draft.inputState = InputState.ENTERED;
          draft.values = {
            ...draft.values,
            beatmungNotfall: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.beatmungNotfall;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      VENTILATION_RECORD_ELEMENT_KEY,
    );
  }

  const combinedRadioList = () => {
    return (
      <>
        {ventilationItems.slice(0, 1).map(([key, label], index) => (
          <FormControlLabel key={`list1-${index}`} value={key} control={<Radio />} label={label} />
        ))}
        <Typography variant='body1' className='list-subheading'>
          {' '}
          maschinell
        </Typography>
        <div className='indented'>
          {ventilationItems.slice(1).map(([key, label], index) => (
            <FormControlLabel key={`list1-second-${index}`} value={key} control={<Radio />} label={label} />
          ))}
        </div>
      </>
    );
  };

  return (
    <DiviFieldCard cardType={CardId.Ventilation}>
      <div className='measures-ventilation'>
        <div className='symptoms-list'>
          <FormControl fullWidth>
            <RadioGroup onChange={handleVentilationRadio} value={ventilationValue}>
              {combinedRadioList()}
            </RadioGroup>
          </FormControl>
        </div>
        <div className='row'>
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 0.1, min: 0.1 }}
            label='FiO2'
            variant='outlined'
            value={record.inputState === InputState.ENTERED ? record.values.beatmungFio2?.number.toString() ?? '' : ''}
            onDebounceChange={changeFIO2}
          />
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label='AF'
            variant='outlined'
            value={record.inputState === InputState.ENTERED ? record.values.beatmungAf?.number.toString() ?? '' : ''}
            onDebounceChange={changeAF}
          />
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 0.1, min: 0.1 }}
            label='AMV'
            variant='outlined'
            value={record.inputState === InputState.ENTERED ? record.values.beatmungAmv?.number.toString() ?? '' : ''}
            onDebounceChange={changeAMV}
          />
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label='PEEP'
            variant='outlined'
            value={record.inputState === InputState.ENTERED ? record.values.beatmungPeep?.number.toString() ?? '' : ''}
            onDebounceChange={changePEEP}
          />
          <DebouncedTextField
            fullWidth
            type='number'
            inputProps={{ step: 1, min: 1 }}
            label='P_max'
            variant='outlined'
            value={record.inputState === InputState.ENTERED ? record.values.beatmungPmax?.number.toString() ?? '' : ''}
            onDebounceChange={changePMax}
          />
        </div>
        <CheckboxList
          className='symptoms-list'
          items={[emergencyAnesthesiaItem]}
          selectedValues={record.values?.beatmungNotfall?.options ?? []}
          onValuesChange={setEmergancyAnesthesia}
        />
      </div>
    </DiviFieldCard>
  );
}
