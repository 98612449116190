import React, { useContext, useState } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId, getCardTitle } from '../../../../models/diviCard';
import { NodeType } from '../../../../backendModels/report.model';
import { Button, IconButton, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useCSS } from '../../../../provider/CSSProvider';
import style from './Trends.scss';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import { ReactComponent as DeleteIcon } from '../../../../assets/images/icons/delete.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/images/icons/upload.svg';
import { getFormattedTime } from '../../../../utils/util';
import CellTemplate, { ExtendedTrendEntry } from './CellTemplate';
import HeaderCellTemplate from './HeaderCellTemplate';
import checked1 from '../../../../assets/images/icons/checkbox-checked-legend.png';
import checked2 from '../../../../assets/images/icons/always-checked-legend.png';
import unchecked from '../../../../assets/images/icons/checkbox-unchecked-legend.png';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { isTrendType, TrendSelection, TrendsRecord } from '../../../../models/trendsRecord';
import { castDraft, Draft } from 'immer';
import _ from 'lodash';
import { VitalParameterCode } from '../../../../backendModels/trends.model';
import { TrendInfoMap } from '../../../../models/trends';

interface TrendsProps {
  nodeType: NodeType;
}

export default function Trends({ nodeType }: TrendsProps) {
  useCSS(style);

  const { findRecordOrDefault, adaptRecord, trends } = useContext(ReportsAPIContext);

  const record = findRecordOrDefault('trends', nodeType);

  //TODO 319 remove useState
  const [manualData, setManualData] = useState<TrendInfoMap>(new Map());

  const updateData = () => {
    adaptRecord('trends', nodeType, (draft: Draft<TrendsRecord>, deleteRecord) => {
      const trendsMap = _.groupBy(draft.trends, 'timestamp');

      for (const datasource of trends ?? []) {
        for (const datapoint of datasource.timeSeries) {
          const timestampHHmm = datapoint.timestamp;
          if (trendsMap[timestampHHmm] == null) {
            trendsMap[timestampHHmm] = [];
          }

          const trendEntry = {
            timestamp: timestampHHmm,
            parameters: datapoint.values
              .filter((oneParameter) => isTrendType(oneParameter.code))
              .map((oneParameter) =>
                castDraft({
                  type: oneParameter.code,
                  value: oneParameter.value,
                  unit: oneParameter.unit,
                  selection: TrendSelection.parameterIsSelected,
                }),
              ),
          };

          // Add the entry to the map if there is no existing entry which matches exactly ignoring selection.
          if (
            !trendsMap[timestampHHmm].some(
              (oneTrend) =>
                trendEntry.parameters.length === oneTrend.parameters.length &&
                _.zip(trendEntry.parameters, oneTrend.parameters).every(([parameter1, parameter2]) =>
                  _.isEqual(_.omit(parameter1, 'selection'), _.omit(parameter2, 'selection')),
                ),
            )
          ) {
            trendsMap[timestampHHmm].push(trendEntry);
          }
        }
      }

      draft.trends = Object.values(trendsMap).flat();

      if (draft.trends.length === 0) {
        deleteRecord();
      }
    });
  };

  function updateParameterChecked(originalIndex: number, trendTypes: VitalParameterCode[], checked: boolean) {
    adaptRecord('trends', nodeType, (draft: Draft<TrendsRecord>) => {
      const trendParameters = draft.trends[originalIndex]?.parameters?.filter((oneParameter) =>
        trendTypes.includes(oneParameter.type),
      );
      if (trendParameters != null) {
        for (const oneParameter of trendParameters) {
          oneParameter.selection = checked ? TrendSelection.parameterIsSelected : TrendSelection.valueNotAvailable;
        }
      } else {
        console.error('Vital Parameter was removed while it was interacted with.');
      }
    });
  }

  function addManualTrendValue() {
    setManualData((prev) => {
      const newTrendInfoMap = new Map(prev);
      newTrendInfoMap.set(Date.now().valueOf(), {
        parameters: [
          {
            type: 'HR',
            value: 52.0,
          },
          {
            type: 'SPO2',
            value: 92.9,
          },
          {
            type: 'CO2',
            value: 35.1,
          },
          {
            type: 'NIBP_SYS',
            value: 120.0,
          },
          {
            type: 'NIBP_DIA',
            value: 79.0,
          },
        ],
      });
      return newTrendInfoMap;
    });
  }

  const sortedTrends = _.sortBy(
    _.concat<ExtendedTrendEntry>(
      record.trends.map((trend, originalIndex) => ({ ...trend, originalIndex, isManuallyAdded: false })),
      [...manualData.entries()].map(([timestamp, trend]) => ({ ...trend, timestamp, isManuallyAdded: true })),
    ),
    (entry) => entry.timestamp,
  );

  const titleContent = (
    <div className='card-title-content'>
      <div>
        <Typography align='left' variant='h2'>
          {getCardTitle(CardId.Trends)}
        </Typography>
        <div className='legend row'>
          <div className='row'>
            <img src={checked1} alt={'checked legend'} /> <img src={checked2} alt={'checked legend'} />
            <Typography variant='subtitle1'>Wird auf dem Protokoll gedruckt</Typography>
          </div>
          <div className='row'>
            <img src={unchecked} alt={'unchecked legend'} />{' '}
            <Typography variant='subtitle1'>Nur online einsehbar</Typography>
          </div>
        </div>
      </div>
      {sortedTrends.length !== 0 && (
        <Button onClick={updateData} variant='outlined' color='inherit' endIcon={<SyncIcon />}>
          Daten aktualisieren
        </Button>
      )}
    </div>
  );

  return (
    <DiviFieldCard cardType={CardId.Trends} customTitleContent={titleContent}>
      <div className='trends-buttons'>
        <Button onClick={addManualTrendValue} variant='outlined' endIcon={<AddCircleIcon />}>
          Werte manuell hinzufügen
        </Button>
      </div>
      <Table className={'trends-table'}>
        <TableHead>
          <TableRow>
            <TableCell>Zeitpunkt</TableCell>
            <HeaderCellTemplate title='Puls' unit='1/min' />
            <HeaderCellTemplate title='NIBP' unit='mmHg' />
            <HeaderCellTemplate
              title={
                <>
                  SpO<sub>2</sub>
                </>
              }
              unit='%'
            />
            <HeaderCellTemplate
              title={
                <>
                  etCO<sub>2</sub>
                </>
              }
              unit='mmHg'
            />
            <TableCell align='right'>Löschen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTrends.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align='center'>
                <Button
                  className='import-button'
                  onClick={updateData}
                  variant='contained'
                  color='primary'
                  endIcon={<UploadIcon />}
                >
                  Daten importieren
                </Button>
              </TableCell>
            </TableRow>
          ) : (
            sortedTrends.map((trendInfo, index) => (
              <TableRow key={index}>
                <TableCell>{getFormattedTime(trendInfo.timestamp)}</TableCell>
                <CellTemplate trendInfo={trendInfo} trendTypes={['HR']} updateChecked={updateParameterChecked} />
                <CellTemplate
                  trendInfo={trendInfo}
                  trendTypes={['NIBP_SYS', 'NIBP_DIA']}
                  updateChecked={updateParameterChecked}
                />
                <CellTemplate trendInfo={trendInfo} trendTypes={['SPO2']} updateChecked={updateParameterChecked} />
                <CellTemplate trendInfo={trendInfo} trendTypes={['CO2']} updateChecked={updateParameterChecked} />
                <TableCell align='right'>
                  {trendInfo.isManuallyAdded && (
                    <IconButton aria-label='delete'>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </DiviFieldCard>
  );
}
