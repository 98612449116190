import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped } from '../generic';
import { OxygenApplicationMethodKeys } from '../oxygen';
import { NumericFieldValue } from '../../../pdfBackend/src/backendModels/generic-element.model';

export const OXYGEN_MEASSURES_RECORD_ELEMENT_KEY = 'oxygenMassnahmen';

export type OxygenMeassuresRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof OXYGEN_MEASSURES_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          sauerstoffgabe?: NumericFieldValue;
          applikationsmethoden?: MultiSelectFieldValueTyped<OxygenApplicationMethodKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );
