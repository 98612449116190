import React from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import RadioList from '../../../customs/radioList/RadioList';
import {
  ExtendedPatientDataRecord,
  getGenderTuplesFor,
  PatientPropertyKeys,
} from '../../../../models/genericElements/extendedPatientData';
import { useFreshCallback } from '../../../../utils/hooks';
import { PatientGender } from '../../../../backendModels/patient.model';
import { HandleChangedPatientProperty } from '../PatientMasterData';

const genderRadioItems: [PatientGender, string][] = getGenderTuplesFor([
  PatientGender.MALE,
  PatientGender.FEMALE,
  PatientGender.UNKNOWN,
]);

interface GenderProps {
  patientDataRecord: ExtendedPatientDataRecord;
  handleChangedPatientProperty: HandleChangedPatientProperty;
  getTouchedClassName: (property: PatientPropertyKeys) => string;
}

export default function Gender({ patientDataRecord, handleChangedPatientProperty, getTouchedClassName }: GenderProps) {
  const handleGenderChange = useFreshCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangedPatientProperty(
      PatientPropertyKeys.gender,
      event.target.value != '' ? { option: event.target.value as PatientGender, fieldType: 'singleSelect' } : undefined,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.Gender}>
      <RadioList
        className={`symptoms-list ${getTouchedClassName(PatientPropertyKeys.gender)}`}
        items={genderRadioItems}
        value={patientDataRecord?.values?.gender?.option ?? null}
        onChange={handleGenderChange}
      />
    </DiviFieldCard>
  );
}
