import React, { useContext } from 'react';
import DiviFieldCard from '../../../customs/DiviFieldCard/DiviFieldCard';
import { CardId } from '../../../../models/diviCard';
import DebouncedTextField from '../../../customs/debouncedTextField/DebouncedTextField';
import style from './PatientData.scss';
import globalstyle from '../../../../global.scss';
import { useCSS } from '../../../../provider/CSSProvider';
import { ReportsAPIContext } from '../../../../provider/ReportsAPIProvider';
import { useFreshCallback } from '../../../../utils/hooks';
import { Draft } from 'immer';
import {
  BirthDateBackendFormat,
  BirthDateUiFormat,
  EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
  ExtendedPatientDataRecord,
  getPseudonymTuplesFor,
  PatientPropertyKeys,
  PseudonymKeys,
} from '../../../../models/genericElements/extendedPatientData';
import { InputState } from '../../../../backendModels/general.model';
import { NodeType } from '../../../../backendModels/report.model';
import { isGenericRecordDeletable } from '../../../../models/generic';
import CheckboxList from '../../../customs/checkboxList/CheckboxList';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { HandleChangedPatientProperty } from '../PatientMasterData';

interface PatientDataProps {
  nodeType: NodeType;
  patientDataRecord: ExtendedPatientDataRecord;
  handleChangedPatientProperty: HandleChangedPatientProperty;
  getTouchedClassName: (property: PatientPropertyKeys) => string;
}

export default function PatientData({
  nodeType,
  patientDataRecord,
  handleChangedPatientProperty,
  getTouchedClassName,
}: PatientDataProps) {
  useCSS(style);
  useCSS(globalstyle);

  const { adaptRecord } = useContext(ReportsAPIContext);

  const birthDate =
    patientDataRecord.values?.birthday?.text != null
      ? dayjs(patientDataRecord.values?.birthday?.text, BirthDateBackendFormat)
      : null;

  const handleBirthDateChange = useFreshCallback((value: dayjs.Dayjs | null) => {
    const newValue = value != null && value?.isValid() ? value.format(BirthDateBackendFormat) : '';
    handleChangedPatientProperty(
      PatientPropertyKeys.birthday,
      newValue !== '' ? { text: newValue, fieldType: 'text' } : undefined,
    );
  });

  const handlePseoudonymChange = useFreshCallback((value: PseudonymKeys[]) => {
    adaptRecord(
      'generic',
      nodeType,
      (draft: Draft<ExtendedPatientDataRecord>, deleteRecord) => {
        draft.inputState = InputState.ENTERED;
        if (value.length > 0) {
          draft.values = {
            ...draft.values,
            pseudonym: {
              fieldType: 'multiSelect',
              options: value,
            },
          };
        } else {
          delete draft.values?.pseudonym;
          if (isGenericRecordDeletable(draft)) {
            deleteRecord();
          }
        }
      },
      EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY,
    );
  });

  return (
    <DiviFieldCard cardType={CardId.PatientData}>
      <div className='patient-data-wireframe'>
        <DebouncedTextField
          className={getTouchedClassName(PatientPropertyKeys.surname)}
          label='Name'
          value={patientDataRecord.values?.surname?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedPatientProperty(
              PatientPropertyKeys.surname,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <DebouncedTextField
          className={getTouchedClassName(PatientPropertyKeys.name)}
          label='Vorname'
          value={patientDataRecord.values?.name?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedPatientProperty(
              PatientPropertyKeys.name,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateField
            fullWidth
            className={`no-margin-top ${getTouchedClassName(PatientPropertyKeys.birthday)}`}
            label='Geburtsdatum'
            value={birthDate}
            format={BirthDateUiFormat}
            onChange={handleBirthDateChange}
          />
        </LocalizationProvider>

        <CheckboxList
          className='symptoms-list'
          items={getPseudonymTuplesFor([PseudonymKeys.pseudonym])}
          selectedValues={
            patientDataRecord.inputState === InputState.ENTERED
              ? ((patientDataRecord.values.pseudonym?.options as PseudonymKeys[]) ?? [])
              : []
          }
          onValuesChange={handlePseoudonymChange}
        />
        <DebouncedTextField
          className={getTouchedClassName(PatientPropertyKeys.street)}
          label='Straße'
          value={patientDataRecord.values?.street?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedPatientProperty(
              PatientPropertyKeys.street,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
        <div className='row patient-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(PatientPropertyKeys.postalCode)}
            fullWidth
            label='PLZ'
            value={patientDataRecord.values?.postalCode?.text ?? ''}
            inputProps={{ min: 1, max: 99999, step: 1, maxLength: 5 }}
            type='number'
            onDebounceChange={(value) =>
              handleChangedPatientProperty(
                PatientPropertyKeys.postalCode,
                value !== '' ? { text: value, fieldType: 'text' } : undefined,
              )
            }
          />
          <DebouncedTextField
            className={`patient-data-city ${getTouchedClassName(PatientPropertyKeys.city)}`}
            fullWidth
            label='Ort'
            value={patientDataRecord.values?.city?.text ?? ''}
            onDebounceChange={(value) =>
              handleChangedPatientProperty(
                PatientPropertyKeys.city,
                value !== '' ? { text: value, fieldType: 'text' } : undefined,
              )
            }
          />
        </div>
        <div className='row insurance-data-location'>
          <DebouncedTextField
            className={getTouchedClassName(PatientPropertyKeys.insuranceName)}
            label='Kasse'
            value={patientDataRecord.values?.insuranceName?.text ?? ''}
            onDebounceChange={(value) =>
              handleChangedPatientProperty(
                PatientPropertyKeys.insuranceName,
                value !== '' ? { text: value, fieldType: 'text' } : undefined,
              )
            }
          />
          <DebouncedTextField
            className={getTouchedClassName(PatientPropertyKeys.insuranceNumber)}
            label='Kasse / Nr.'
            value={patientDataRecord.values?.insuranceNumber?.text ?? ''}
            onDebounceChange={(value) =>
              handleChangedPatientProperty(
                PatientPropertyKeys.insuranceNumber,
                value !== '' ? { text: value, fieldType: 'text' } : undefined,
              )
            }
          />
        </div>

        <DebouncedTextField
          className={getTouchedClassName(PatientPropertyKeys.policyNumber)}
          label='Versicherungs Nr.'
          value={patientDataRecord.values?.policyNumber?.text ?? ''}
          onDebounceChange={(value) =>
            handleChangedPatientProperty(
              PatientPropertyKeys.policyNumber,
              value !== '' ? { text: value, fieldType: 'text' } : undefined,
            )
          }
        />
      </div>
    </DiviFieldCard>
  );
}
