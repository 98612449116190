import { NodeRecordTypeMap } from '../../backendModels/records.model';
import { InputState } from '../../backendModels/general.model';
import { MultiSelectFieldValueTyped, SingleSelectFieldValueTyped } from '../generic';
import { PatientGender } from '../../backendModels/patient.model';
import { NumericFieldValue, TextFieldValue } from '../../backendModels/generic-element.model';

export enum BmiKeys {
  ueber40 = 'ABOVE_40',
  unterOderGleich40 = 'UNDER_OR_EQUAL_40',
}

export enum PseudonymKeys {
  pseudonym = 'IS_PSEUDONYM',
}

export enum NewBornAgeKeys {
  eineWocheAlt = '1_7_DAYS',
  zwischenZweiUndVierWochenAlt = '8_28_DAYS',
}

export const EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY = 'diviExtendedPatientData';

export enum PatientPropertyKeys {
  name = 'name',
  surname = 'surname',
  birthday = 'birthday',
  street = 'street',
  postalCode = 'postalCode',
  city = 'city',
  insuranceNumber = 'insuranceNumber',
  insuranceName = 'insuranceName',
  policyNumber = 'policyNumber',
  age = 'age',
  gender = 'gender',
}

export type ExtendedPatientDataRecord = NodeRecordTypeMap['generic'] &
  (
    | {
        readonly elementKey: typeof EXTENDED_PATIENT_DATA_RECORD_ELEMENT_KEY;
        readonly inputState: InputState.ENTERED;
        readonly values: {
          surname?: TextFieldValue;
          name?: TextFieldValue;
          gender?: SingleSelectFieldValueTyped<PatientGender>;
          age?: NumericFieldValue;
          birthday?: TextFieldValue;
          street?: TextFieldValue;
          postalCode?: TextFieldValue;
          city?: TextFieldValue;
          policyNumber?: TextFieldValue;
          insuranceName?: TextFieldValue;
          insuranceNumber?: TextFieldValue;
          bmi?: SingleSelectFieldValueTyped<BmiKeys>;
          pseudonym?: MultiSelectFieldValueTyped<PseudonymKeys>;
          newbornAge?: SingleSelectFieldValueTyped<NewBornAgeKeys>;
          touched?: MultiSelectFieldValueTyped<PatientPropertyKeys>;
        };
      }
    | {
        readonly inputState: InputState.UNKNOWN;
        readonly values: null;
      }
  );

export const BirthDateBackendFormat = 'YYYY-MM-DD';
export const BirthDateUiFormat = 'DD.MM.YYYY';

export const bmiLabels: Record<BmiKeys, string> = {
  [BmiKeys.ueber40]: '> 40',
  [BmiKeys.unterOderGleich40]: '≤ 40',
};

export function getBmiTuple(key: BmiKeys): [BmiKeys, string] {
  return [key, bmiLabels[key]];
}

export function getBmiTuplesFor(keys: BmiKeys[]): [BmiKeys, string][] {
  return keys.map((k) => {
    return getBmiTuple(k);
  });
}

export const newBornAgeLabels: Record<NewBornAgeKeys, string> = {
  [NewBornAgeKeys.eineWocheAlt]: '1-7 Tage',
  [NewBornAgeKeys.zwischenZweiUndVierWochenAlt]: '8-28 Tage',
};

export function getNewBornAgeTuple(key: NewBornAgeKeys): [NewBornAgeKeys, string] {
  return [key, newBornAgeLabels[key]];
}

export function getNewBornAgeTuplesFor(keys: NewBornAgeKeys[]): [NewBornAgeKeys, string][] {
  return keys.map((k) => {
    return getNewBornAgeTuple(k);
  });
}

export const pseudonymLabels: Record<PseudonymKeys, string> = {
  [PseudonymKeys.pseudonym]: 'Pseudonym',
};

export function getPseudonymTuple(key: PseudonymKeys): [PseudonymKeys, string] {
  return [key, pseudonymLabels[key]];
}

export function getPseudonymTuplesFor(keys: PseudonymKeys[]): [PseudonymKeys, string][] {
  return keys.map((k) => {
    return getPseudonymTuple(k);
  });
}

export const genderLabels: Record<PatientGender, string> = {
  [PatientGender.MALE]: 'männlich',
  [PatientGender.FEMALE]: 'weiblich',
  [PatientGender.UNKNOWN]: 'divers',
};

export function getGenderTuple(key: PatientGender): [PatientGender, string] {
  return [key, genderLabels[key]];
}

export function getGenderTuplesFor(keys: PatientGender[]): [PatientGender, string][] {
  return keys.map((k) => {
    return getGenderTuple(k);
  });
}
