import { Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TrendParameter, TrendSelection } from '../../../../models/trendsRecord';
import { VitalParameterCode } from '../../../../backendModels/trends.model';

export type ExtendedTrendEntry =
  | { timestamp: number; isManuallyAdded: false; originalIndex: number; parameters: TrendParameter[] }
  | { timestamp: number; isManuallyAdded: true; parameters: { type: VitalParameterCode; value: number }[] };

interface CellTemplateProps {
  trendInfo: ExtendedTrendEntry;
  updateChecked: (index: number, trendTypes: VitalParameterCode[], checked: boolean) => void;
  trendTypes: VitalParameterCode[];
}

export default function CellTemplate({ trendInfo, updateChecked, trendTypes }: CellTemplateProps) {
  const hasValue = trendTypes.some((oneTrendType) =>
    trendInfo.parameters.some((oneParameter) => oneParameter.type === oneTrendType),
  );

  return (
    <TableCell>
      <div className='trends-table-cell'>
        <div className={hasValue ? '' : 'hide-check'}>
          {trendInfo.isManuallyAdded ? (
            <CheckIcon className={`trends-check-icon`} color='primary' />
          ) : (
            <Checkbox
              checked={trendTypes.some((oneTrendType) =>
                trendInfo.parameters.some(
                  (oneParameter) =>
                    oneParameter.type === oneTrendType && oneParameter.selection === TrendSelection.parameterIsSelected,
                ),
              )}
              onChange={(event) => updateChecked(trendInfo.originalIndex, trendTypes, event.target.checked)}
            />
          )}
        </div>
        <div>
          {trendTypes
            .map(
              (oneTrendType) =>
                trendInfo.parameters
                  .find((oneParameter) => oneParameter.type === oneTrendType)
                  ?.value?.toLocaleString() ?? '--',
            )
            .join(' / ')}
        </div>
      </div>
    </TableCell>
  );
}
